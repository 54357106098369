import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Quote from "../components/quote";
import Form from "../components/form";
import Helmet from 'react-helmet';

const Webpage = ({ data }) => {
    const item = data.kontentItemChannelWebpage.elements;
    const quote1Item = item.quote.linked_items[0].elements;
    const formItem = item.form.linked_items[0].elements;
    const siteURLPath = data.site.siteMetadata.siteUrl + `/`;
    const imagePath = item.metadata__og_image.value[0].url;

  return (
    <Layout>
        <Helmet>
          <html lang="en" />

          {/* General tags */}
          <title>{item.metadata__title.value} | MMT Digital Memberships</title>
          <meta name="description" content={item.metadata__description.value} />
          <meta name="keywords" content={item.metadata__keywords.value} />
          <meta name="image" content={imagePath} />
          <link rel="canonical" href={siteURLPath} />

          {/* Twitter Card tags */}
          <meta name="twitter:url" content={siteURLPath} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:creator" content={data.site.siteMetadata.twitter} />
          <meta name="twitter:title" content={item.metadata__og_title.value} />
          <meta name="twitter:description" content={item.metadata__og_description.value} />
          <meta name="twitter:image" content={imagePath} />
          <meta name="twitter:site" content={siteURLPath} />

          {/* OpenGraph tags */}
          <meta property="og:url" content={siteURLPath} />
          <meta property="og:type" content="webpage" />
          <meta property="og:title" content={item.metadata__og_title.value} />
          <meta property="og:description" content={item.metadata__og_description.value} />
          <meta property="og:image" content={imagePath} />
          <meta property="og:image:secure_url" content={imagePath} />
        </Helmet>
        <section className="solid-hero" >
            <div className="left-column">
                <h1 className="">{item.metadata__title.value}</h1>
                <div dangerouslySetInnerHTML={{ __html: item.content0.value }} />
            </div>
            <div className="right-column">
                <Form
                    formHeading={formItem.title.value}
                    formCampaignType={formItem.campaign_name.value}
                    />
            </div>
        </section>

        <section className="outer-panel">
            <div className="inner-panel general-content">
                <div dangerouslySetInnerHTML={{ __html: item.content1.value }} />
            </div>
        </section>

        <section className="quote-panel outer-panel grey">
            <div className="inner-panel"> 
                <Quote 
                    clientQuoteText={quote1Item.quote.value}
                    clientQuotePersonalDetails={quote1Item.personal_details.value}
                    clientQuoteLogoUrl={quote1Item.logo.value[0].url}
                    clientQuoteLogoAltText={quote1Item.client_name.value}
                    />
            </div>
        </section>
            
        <section className="outer-panel">
            <div className="inner-panel general-content">
                <div dangerouslySetInnerHTML={{ __html: item.content2.value }} />
            </div>
        </section>

        <section className="contact-panel outer-panel dark-grey">
            <div className="inner-panel"> 
                <div dangerouslySetInnerHTML={{ __html: item.contact_panel.linked_items[0].elements.contact_text.value }} />
                <a id="hypLink" className="cta-space-above cta-button cta-button-fill" href={item.contact_panel.linked_items[0].elements.cta_url.value}>{item.contact_panel.linked_items[0].elements.cta_text.value}</a>   
            </div>
        </section>
    </Layout>
  )
}

export default Webpage

export const query = graphql`
  query webpageQuery($slug: String!) {
    kontentItemChannelWebpage(fields: { slug: { eq: $slug } }) {
        fields {
            slug
        }
        elements {
            metadata__title {
                value
            }
            metadata__description {
                value
            }
            metadata__keywords {
                value
            }
            metadata__og_title {
                value
            }
            metadata__og_description {
                value
            }
            metadata__og_image {
                value {
                    url
                }
            }
            content0 {
                value
            }
            form {
                linked_items {
                    ... on KontentItemForm {
                        elements {
                            title {
                                value
                            }
                            campaign_name {
                                value
                            }
                        }
                    }
                }
            }
            content1 {
                value
            }
            quote {
                linked_items {
                    ... on KontentItemClientQuote {
                        elements {
                            logo {
                                value {
                                    url
                                }
                            }
                            client_name {
                                value
                            }
                            personal_details {
                                value
                            }
                            quote {
                                value
                            }
                        }
                    }
                }
            }
            content2 {
                value
            }
            contact_panel {
                linked_items {
                    ... on KontentItemContactPanel {
                        elements {
                            contact_text {
                                value
                            }
                            cta_text {
                                value
                            }
                            cta_url {
                                value
                            }
                        }
                    }
                }
            }
        }
    }
    site {
      siteMetadata {
        siteUrl
        title
        twitter
        description
      }
    }
}
`
