import PropTypes from "prop-types"
import React from "react"

function stringifyFormData(fd) {
    const data = {};
    for (let key of fd.keys()) {
        data[key] = fd.get(key);
    }
    return JSON.stringify(data, null, 2);
}

const inputParsers = {
    uppercase(input) {
        return input.toUpperCase();
    }
};
  
class ShakingError extends React.Component {
        constructor() { super(); this.state = { key: 0 }; }

        componentWillReceiveProps() {
        // update key to remount the component to rerun the animation
        this.setState({ key: ++this.state.key });
    }

    render() {
        return <div key={this.state.key} className="bounce">{this.props.text}</div>;
    }
}

const Form = ({ formCampaignType, formHeading }) => (
    <div className="contact-form" id="ContactForm">
        <h2>{formHeading}</h2>
        
        <MyForm formCampaignType={formCampaignType} />
    </div>
)

Form.propTypes = {
    formHeading: PropTypes.string, 
    formCampaignType: PropTypes.string,
}

Form.defaultProps = {
    formHeading: ``, 
    formCampaignType: ``,
}

export default Form

  class MyForm extends React.Component {
    constructor() {
      super();
      this.state = {};
      this.handleSubmit = this.handleSubmit.bind(this);
    }
  
    handleSubmit(event) {
      event.preventDefault();
      const form = event.target;
      const emailFormElement = event.target.elements[4];
      const emailFormValue = emailFormElement.value;
      let emailValid = false; 
   
      if (emailFormElement.className === "contact-form-input contact-form-email") {
        const re = /^((?!\.)[\w-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/;
        const result = re.test(String(emailFormValue).toLowerCase());
        emailValid = result;
      }

      if (!event.target.checkValidity() || !emailValid) {
          this.setState({
          invalid: true,
          displayErrors: true,
        });
        return;
      }

      const data = new FormData(form);
      for (let name of data.keys()) {
        const input = form.elements[name];
        const parserName = input.dataset.parse;
        if (parserName) {
          const parsedValue = inputParsers[parserName](data.get(name))
          data.set(name, parsedValue);
        }
      }
      
      this.setState({
        res: stringifyFormData(data),
        invalid: false,
        displayErrors: false,
      });
       
      fetch('https://api.pipedrive.com/v1/persons?api_token=e735830e7882b8b7e6996c641d4d700d5757baa1', {
        method: 'POST',
        body: data,
      });

      form.setAttribute('hidden', true);
    }
  
    render() {
        const { res, invalid, displayErrors } = this.state;
        const campaignTypeName = this.props.formCampaignType;
      return (
          <div>
              <div className="res-block">
            {invalid && (
              <ShakingError text="Please enter valid data into the form" />
            )}
            {!invalid && res && (
                <div className="form-conformation">
                    <p>Thank you for submitting your details. We will be in contact soon.</p>
                </div>
            )}
          </div>
          <form
            id="MMTCampaignForm"
            onSubmit={this.handleSubmit}
            noValidate
            className={displayErrors ? 'displayErrors' : ''}
           >
            <div className="form-group form-group-left">      
              <input className="contact-form-input" id="name" name="name" type="text" required />
              <span className="highlight"></span>
              <span className="bar"></span>
              <label className="contact-form-label"  htmlFor="name">Name</label>
            </div>

            <div className="form-group">      
              <input className="contact-form-input" id="phone" name="phone" type="text" required />
              <span className="highlight"></span>
              <span className="bar"></span>
              <label className="contact-form-label" htmlFor="phone">Phone number</label>
            </div>
            
            <div className="form-group form-group-left">      
              <input className="contact-form-input" id="job_title" name="job_title" type="text" required />
              <span className="highlight"></span>
              <span className="bar"></span>
              <label className="contact-form-label"  htmlFor="job_title">Job title</label>
            </div>
            
            <div className="form-group">      
              <input className="contact-form-input" id="e609eea0f10c4258d94d130b8ed14098b2896d72" name="e609eea0f10c4258d94d130b8ed14098b2896d72" type="text" required />
              <span className="highlight"></span>
              <span className="bar"></span>
              <label className="contact-form-label"  htmlFor="e609eea0f10c4258d94d130b8ed14098b2896d72">Company</label>
            </div>
            
            <div className="form-group form-group-full">      
              <input className="contact-form-input contact-form-email" id="email" name="email" type="text" required />
              <span className="highlight"></span>
              <span className="bar"></span>
              <label className="contact-form-label"  htmlFor="email">Email</label>
            </div>

            <div className="form-group">      
              <input className="hide-element" id="b3292dc96538f9c22a7a5ecb95ebbeea4b572f5e" name="b3292dc96538f9c22a7a5ecb95ebbeea4b572f5e" type="text" defaultValue={campaignTypeName} />
              <span className="highlight"></span>
              <span className="bar"></span>
              <label className="hide-element" htmlFor="b3292dc96538f9c22a7a5ecb95ebbeea4b572f5e">Campaign Type</label>
            </div>
  
            <div className="contact-form-button">
              <button id="MMTCampaingButton" className="cta-button">Submit</button>
            </div>
          </form>
        </div>
      );
    }
  }
  
  
