import PropTypes from "prop-types"
import React from "react"

const Footer = ({ siteUrl, siteAuthor }) => (
  <footer className="footer-links">
    <nav>
      <div className="right">
          <ul className="awards-links">
              <li><img src="https://mmt-cdn-prod-media-01.azureedge.net/mediacontainer/medialibraries/mmtdigital/mmtdigital/assets/the-drum-awards-b-w.png?ext=.png" alt="The Drum Footer" /></li><li><img src="https://mmt-cdn-prod-media-01.azureedge.net/mediacontainer/medialibraries/mmtdigital/mmtdigital/about/econsultancy-for-footer.png?ext=.png" alt="Econsultancy Footer"/></li>
          </ul>
      </div>

      <div className="left">
          <ul className="contact-links">
              <li className="contact-links--mail">
                  <a id="hypEmail" title="Email us" href="mailto:hello@mmtdigital.co.uk">hello@mmtdigital.co.uk</a></li>
              <li className="contact-links--tel">
                  <a id="hypNumber" title="Give us a call" href="tel:02072425698">t. 020 7242 5698</a></li>
              <li className="social-links-li">
                  <ul className="social-links">
                      <li className="social-links--linkedin">
                        <a href="https://www.linkedin.com/company/mmt-digital/" title="Our LinkedIn Account">
                          <i className="mmt-ico mmt-ico-linkedin" aria-hidden="true"></i>
                        </a>
                      </li><li className="social-links--twitter">
                        <a href="https://twitter.com/mmt_digital" title="Our Twitter Account">
                          <i className="mmt-ico mmt-ico-twitter" aria-hidden="true"></i>
                        </a>
                      </li>
                    </ul>
                </li>
            </ul>

            <ul className="legal-links">
                <li><a href="/Cookies">Cookies</a></li><li><a href="/Privacy-Policy">Privacy Policy</a></li><li><a href="/Sitemap">Sitemap</a></li>
            </ul>
        </div>
    </nav>
</footer>
)

Footer.propTypes = {
  siteUrl: PropTypes.string,
  siteAuthor: PropTypes.string,
}

Footer.defaultProps = {
  siteUrl: ``,
  siteAuthor: ``,
}

export default Footer
