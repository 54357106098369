import PropTypes from "prop-types"
import React from "react"
import logo from "../../src/assets/mmt-logo-white.png"

const Header = ({ siteTitle, siteUrl }) => (
  <header className="logo-container headroom headroom--not-top headroom--not-bottom headroom--pinned headroom--top">
    <a className="logo"
        href={siteUrl} 
        rel="noopener noreferrer"
        target="_blank">
        <img className="mmt-logo" src={logo} alt={siteTitle} />
    </a>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
  siteUrl: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
  siteUrl: ``,
}

export default Header
