import PropTypes from "prop-types"
import React from "react"

const Quote = ({ clientQuoteText, clientQuotePersonalDetails, clientQuoteLogoUrl, clientQuoteLogoAltText }) => (
    <div className="quote quote-center text-center">
        <div className="quote-content">
            <div id="pnlImage">
                <img id="imgImage" className="quote-image" src={clientQuoteLogoUrl} alt={clientQuoteLogoAltText} />
            
            </div>
            <p className="quote-text text-italic">{clientQuoteText}</p>
            <div id="pnlAuthor">
                <p className="quote-author">{clientQuotePersonalDetails}</p>
            </div>
        </div>
    </div>
)

Quote.propTypes = {
    clientQuoteText: PropTypes.string, 
    clientQuotePersonalDetails: PropTypes.string, 
    clientQuoteLogoUrl: PropTypes.string, 
    clientQuoteLogoAltText: PropTypes.string,
}

Quote.defaultProps = {
    clientQuoteText: ``,
    clientQuotePersonalDetails: ``,
    clientQuoteLogoUrl: ``,
    clientQuoteLogoAltText: ``,
}

export default Quote
